

























import { Api, EditNoteRequest, NoteInfo, NoteTarget } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import Note from './Note.vue'
import NoteSet from './NoteSet.vue'

@Component({
  components: {
    Note, NoteSet
  }
})
export default class Notes extends Mixins(ComponentHelper) {
  @Prop({ required: true }) type!: NoteTarget
  @Prop({ required: true }) targetId!: number
  @Prop({ default: () => [] }) notesProp!: NoteInfo[]

  notes: NoteInfo[] = []
  addingNote: number | true | null = null

  @Watch('notesProp', { immediate: true })
  notePropChanged (val: NoteInfo[]) {
    this.notes = val
  }

  async addNote (message: string) {
    const note = await Api.addNote({ note: message, target_type: this.type, target_id: this.targetId, parent_id: this.addingNote === true ? null : this.addingNote })

    if (note.parent_id === null) {
      this.notes.push(note)
    } else {
      const getParent = (notes) => {
        for (const n of notes) {
          if (n.id === note.parent_id) {
            return n.children
          }

          const p = getParent(n.children)

          if (p) {
            return p
          }
        }

        return false
      }

      const parentArray = getParent(this.notes)
      parentArray.push(note)
    }

    this.$emit('added-note', this.notes)

    this.addingNote = null
  }

  async getNotes () {
    this.notes = await Api.getNotesForTarget(this.type, this.targetId)
  }

  async deleteNote (noteId: number) {
    const isParent = (notes) => {
      for (const note of notes) {
        if (note.parent_id === noteId) {
          return true
        }

        if (isParent(note.children)) {
          return true
        }
      }

      return false
    }

    if (isParent(this.notes)) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Cannot delete a note with replies',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return
    }

    await Api.deleteNote(noteId)

    const getParent = (notes) => {
      for (const n of notes) {
        if (n.id === noteId) {
          return notes
        }

        const p = getParent(n.children)

        if (p) {
          return p
        }
      }

      return false
    }
    const parentArray = getParent(this.notes)

    const index = parentArray.findIndex(n => n.id === noteId)
    parentArray.splice(index, 1)

    this.$emit('deleted-note', this.notes)
  }

  async editNote (noteId: number, data: EditNoteRequest) {
    await Api.editNote(noteId, data)

    const getNote = (notes) => {
      for (const n of notes) {
        if (n.id === noteId) {
          return n
        }

        const p = getNote(n.children)

        if (p) {
          return p
        }
      }

      return false
    }
    const oldNote = getNote(this.notes)
    oldNote.note = data.note
  }
}
