



































































import { CancellationInfo, PagedResults, reasonToLabel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Notes from './Notes.vue'

@Component({
  components: {
    Notes
  }
})
export default class CancellationTable extends Mixins(ComponentHelper) {
  @Prop({ required: true }) data!: PagedResults<CancellationInfo<{notes: true}>>

  addingNote: boolean = false

  reasonToLabel = reasonToLabel
}

