




















































































































































import { Api, CancellationAggregateData, CancellationInfo, GetSubscriptionCancellationReasonsFilters, PagedResults, reasonToLabel, SchoolOrgType, StripeInvoiceAccountRegion, SubscriptionCancellationReason, SubscriptionProductType, TableQuery, TableState, toISO8601Date } from '@/edshed-common/api'
import { Currency } from '@/edshed-common/api/types/currency'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins } from 'vue-property-decorator'
import _ from 'lodash'
import moment from 'moment'
import CancellationPieChart from '../CancellationPieChart.vue'
import CancellationTable from './components/CancellationTable.vue'

@Component({
  name: 'Cancellations',
  components: {
    CancellationPieChart,
    CancellationTable
  }
})
export default class Cancellations extends Mixins(ComponentHelper) {
  reasonToLabel = reasonToLabel

  aggregateData: CancellationAggregateData[] = []

  get totalResponses () {
    return this.aggregateData.reduce((total, r) => total + r.count, 0)
  }

  tableData: PagedResults<CancellationInfo<{ notes: true }>> = {
    items: [],
    total: 0
  }

  tableState: TableState = {
    dir: 'desc',
    sort: 'cancelled_date',
    page: 1,
    perPage: 10,
    term: ''
  }

  get chartData () {
    return {
      labels: SubscriptionCancellationReason.map((r) => {
        return reasonToLabel(r)
      }),
      datasets: [
        {
          label: 'Dataset 1',
          data: SubscriptionCancellationReason.map((r) => {
            return this.aggregateData.find(d => d.reason === r)?.count ?? 0
          }),
          backgroundColor: ['#FFC0CB', '#B0E0E6', '#98FB98', '#FFFACD', '#FFDAB9', '#E6E6FA', '#E0BBE4', '#BDFCC9', '#FFD8B1', '#00CED1'],
          datalabels: {
            labels: {
              value: {
                anchor: 'center',
                backgroundColor (ctx) {
                  return null
                },
                color (ctx) {
                  return 'black'
                },
                display (ctx) {
                  const responses = ctx.dataset.data.reduce((total, r, i) => total + r * (ctx.chart.legend.legendItems[i].hidden ? 0 : 1), 0)
                  const value = ctx.dataset.data[ctx.dataIndex]
                  const percent = (100 * Math.round(value * 1000) / 1000 / responses)
                  return percent > 3
                },
                formatter (value, ctx) {
                  const responses = ctx.dataset.data.reduce((total, r, i) => total + r * (ctx.chart.legend.legendItems[i].hidden ? 0 : 1), 0)
                  return `${(100 * Math.round(value * 1000) / 1000 / responses).toFixed(0)}%`
                }
              },
              name: {
                anchor: 'end',
                font: { size: 10 },
                formatter (value, ctx) {
                  return ctx.chart.data.labels[ctx.dataIndex]
                },
                display (ctx) {
                  const responses = ctx.dataset.data.reduce((total, r, i) => total + r * (ctx.chart.legend.legendItems[i].hidden ? 0 : 1), 0)

                  const value = ctx.dataset.data[ctx.dataIndex]
                  const percent = (100 * Math.round(value * 1000) / 1000 / responses)
                  return percent > 7
                }
              }
            }

          }
        }
      ]
    }
  }

  SubscriptionProductType = SubscriptionProductType

  SubscriptionCancellationReason = SubscriptionCancellationReason

  StripeInvoiceAccountRegion = StripeInvoiceAccountRegion

  Currency = Currency

  SchoolOrgType = SchoolOrgType

  selectedProductTypes: SubscriptionProductType[] = []

  selectedReasons: SubscriptionCancellationReason[] = []

  selectedRegions: StripeInvoiceAccountRegion[] = []

  selectedCurrencies: Currency[] = []

  selectedOrgTypes: SchoolOrgType[] = []

  startDate: Date | null = null

  endDate: Date | null = null

  mounted () {
    this.selectedCurrencies = [...(this.appliedFilters.currency ?? [])]
    this.selectedOrgTypes = [...(this.appliedFilters.org_type ?? [])]
    this.selectedProductTypes = [...(this.appliedFilters.product_type ?? [])]
    this.selectedReasons = [...(this.appliedFilters.reasons ?? [])]
    this.selectedRegions = [...(this.appliedFilters.account_region ?? [])]
    this.startDate = this.appliedFilters.start_date ?? moment().startOf('month').toDate()
    this.endDate = this.appliedFilters.end_date ?? null

    this.applyFilters()
  }

  get appliedFilters (): GetSubscriptionCancellationReasonsFilters {
    return this.stripUndefProps({
      account_region: this.parseQueryEnum('account_region', StripeInvoiceAccountRegion) ?? undefined,
      currency: this.parseQueryEnum('currency', Currency) ?? undefined,
      reasons: this.parseQueryEnum('reasons', SubscriptionCancellationReason) ?? undefined,
      product_type: this.parseQueryEnum('product_type', SubscriptionProductType) ?? undefined,
      org_type: this.parseQueryEnum('org_type', SchoolOrgType) ?? undefined,
      start_date: this.$route.query.start_date ? new Date(parseInt(this.$route.query.start_date as string)) : undefined,
      end_date: this.$route.query.end_date ? new Date(parseInt(this.$route.query.end_date as string)) : undefined
    })
  }

  get currentFilters (): GetSubscriptionCancellationReasonsFilters {
    return this.stripUndefProps({
      account_region: (this.selectedRegions.length > 0 && this.selectedRegions.length < StripeInvoiceAccountRegion.length) ? this.selectedRegions : undefined,
      currency: (this.selectedCurrencies.length > 0 && this.selectedCurrencies.length < Currency.length) ? this.selectedCurrencies : undefined,
      reasons: (this.selectedReasons.length > 0 && this.selectedReasons.length < SubscriptionCancellationReason.length) ? this.selectedReasons : undefined,
      product_type: (this.selectedProductTypes.length > 0 && this.selectedProductTypes.length < SubscriptionProductType.length) ? this.selectedProductTypes : undefined,
      org_type: (this.selectedOrgTypes.length > 0 && this.selectedOrgTypes.length < SchoolOrgType.length) ? this.selectedOrgTypes : undefined,
      start_date: this.startDate ?? undefined,
      end_date: this.endDate ?? undefined
    })
  }

  get haveFiltersChanged () {
    return !_.isEqual(this.appliedFilters, this.currentFilters)
  }

  tablePageChanged (page: number) {
    this.tableState.page = page

    this.getTableData()
  }

  tableSortChanged (column: string, dir: 'asc' | 'desc') {
    this.tableState.sort = column
    this.tableState.dir = dir

    this.getTableData()
  }

  async applyFilters () {
    try {
      await this.getTableData()
      await this.getAggregateData()

      this.updateQueryParams(() => this.stringifyProps(this.stripUndefProps(this.currentFilters)))
    } catch (err) {

    }
  }

  async getTableData () {
    try {
      const data = await Api.useNewQueryFormat().getCancellationReasonsTable(this.currentFilters, {
        dir: this.tableState.dir,
        skip: this.tableState.perPage * (this.tableState.page - 1),
        sort: this.tableState.sort,
        take: this.tableState.perPage,
        term: this.tableState.term
      }, { notes: true })

      this.tableData = data
    } catch (err) {
      this.toastError('Could not load cancellation reasons')
    }
  }

  async getAggregateData () {
    try {
      const data = await Api.getCancellationReasonsAggregated(this.currentFilters)

      this.aggregateData = data
    } catch (err) {
      this.toastError('Could not load cancellation reasons')
    }
  }
}
